import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './src/css/framer-nav.css';
import './src/css/media-600.css';
import './src/css/media-991.css';
import './src/css/styles.css';
import './src/css/utils.css';
import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';
import { navigate } from 'gatsby';
import Brand from './src/Brand';
import {appInsights} from '@capp.micro/shared/components';

export const onClientEntry = () => {
  if (appInsights) {
    appInsights.trackPageView();  // logs initial page view
  }
};

const onRedirectCallback = (appState) => {
  navigate(appState?.returnTo || '/', { replace: true });
};

export const wrapRootElement = ({ element }) => (
  
    <Auth0Provider
      domain={window.auth0Domain}
      clientId={window.auth0ClientId}
      redirectUri={`${window.location.protocol}//${window.location.host}/dashboard`}
      audience={window.apiAudience}
      scope=""
      useRefreshTokens
      cacheLocation="localstorage"
      onRedirectCallback={onRedirectCallback}
      connection={Brand.AUTH_CONNECTION}
    >
      {element}
    </Auth0Provider>
  
);
