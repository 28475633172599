import React from "react"
import { Nav, Navbar } from "react-bootstrap"
import PropTypes from 'prop-types';

const Navigation = ({logo, alt}) => {

  Navigation.propTypes = {

  logo: PropTypes.string,
  alt: PropTypes.string,

}
  
  return (

      <div className='header header-scrolled navbar navbar-expand-lg navbar-light sticky-top'>
        <Navbar.Brand>
         <a href="/"> <img src={logo} alt={alt}/></a>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav"/>

        <Navbar.Collapse
          id="responsive-navbar-nav"
          style={{ justifyContent: "flex-start" }}
        >
          
        </Navbar.Collapse>
        <Nav className="button-nav">
  
          
        </Nav>
      </div>

  )
}


export default Navigation
