const page2Data = {
  "id": "c06e6ac5-164d-4203-8d35-730345a0a0c8",
  "number": "2",
  "title": "",
  "image": "https://apifiles.cappassessments.com/Deloitte2023/findyourpath/question2.png",
  "content": [
     
  ],
  "afterResponse": [
      ""
  ],

  "resourceType":[
     
  ], 

  "responseType": [
      {
          "id": "f77c295b-cb50-49b5-af47-1ee77fd05634",
          "name": "sliderQuestion",
          "class": "slider-container",
          "guid": "ba967814-9323-496a-9ba7-4e18733b3543",
          "questionText": "",
          "questions": [
              {
                  questions: [
                    'I am more of a:',
                  ],
                  categories: {0: 'Creative problem solver, who always finds a way', 1: '', 2: '', 3: 'Rule follower, making sure I do everything exactly right'},
                  max: 3,
                  min: 0,
                  startingValue: 0,
                  increments: 1,
                  showPrevious: false,
                  showFeedback: false,
    
                },
          ],
          },
  ]  
};

module.exports = page2Data;
