import React, { useState, useEffect } from "react"
import { PageComponent } from "../components/pageComponent";
import { Link } from "gatsby"
import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
import Layout from "../components/layout";
import PropTypes from 'prop-types';

const addObjectToLocalStorage = (key, objectToAdd, selectedPageIndex) => {
  // Check if the key already exists in local storage
  if (localStorage.getItem(key)) {
    // If the key exists, retrieve the existing data and parse it as JSON
    let existingData = JSON.parse(localStorage.getItem(key));

    // Ensure that the existing data is an array (if not, initialize it as an empty array)
    if (!Array.isArray(existingData)) {
      existingData = [];
    }

    // Replace the element at selectedPageIndex with objectToAdd
    existingData[selectedPageIndex] = objectToAdd;

    // Convert the updated data back to JSON and store it in local storage
    localStorage.setItem(key, JSON.stringify(existingData));
  } else {
    // If the key doesn't exist, create a new array with the object and store it in local storage
    localStorage.setItem(key, JSON.stringify([objectToAdd]));
  }
}

const ModuleTemplate = ({ pageContext }) => {
  ModuleTemplate.propTypes = {
    pageContext: PropTypes.object
  }
  const { module, phase } = pageContext
  const [selectedPage, setSelectedPage] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedPageIndex, setSelectedPageIndex] = useState(0);


  useEffect(() => {
    if (module.items && module.items.length > 0) {
       setSelectedItem(module.items[0]);
      setSelectedPage(module.items[0].pages[0]);
      setSelectedPageIndex(0);
   
    }
  }, [module]);


  const handleNextPage = () => {
    if (selectedItem?.pages && selectedPageIndex < selectedItem.pages.length - 1) {
        setSelectedPageIndex(prevIndex => {
            const newIndex = prevIndex + 1;
            setSelectedPage(selectedItem.pages[newIndex]);
            return newIndex;
        });    
    }
    window.scrollTo(0, 0);

    }

    const handlePrevPage = () => {
      if (selectedItem?.pages) {
          let newIndex = selectedPageIndex - 1;
          if ((newIndex + 1) % 3 === 0) {
              newIndex--; // skip it
          }
          if (newIndex >= 0) {
              setSelectedPage(selectedItem.pages[newIndex]);
              setSelectedPageIndex(newIndex);
          } else if (module.id > 1) {
              window.location = `/modules/${module.id - 1}`
          } else {
            return;
          }
      }
      window.scrollTo(0, 0);
  };
  
  
    const isLastPageOfModule = (currentItem, currentPageIndex) => {
      if (!currentItem || currentPageIndex === undefined) {return false;}
      return (currentPageIndex + 1) % 3 === 0;
    }

    const isTrueLastPageOfModule = (currentItem, currentPageIndex) => {
      if (!currentItem || currentPageIndex === undefined) {return false;}
      return currentPageIndex === currentItem.pages.length - 1;
  }
  
    
  
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
      if (isLastPageOfModule(selectedItem, selectedPageIndex)) {
          setShowModal(true);
          }
      }, [selectedItem, selectedPageIndex]);

      const isLastModuleOfPhase = () => {
        if (!module || !phase) {
            return false;
        }    
        return module.id === phase.modules.length;
}
    const renderLastPageModal = () => {
      if (isLastPageOfModule(selectedItem, selectedPageIndex) && showModal) {
        return (
          <Modal
            show={showModal}
            onHide={() => setShowModal(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            className="module-modal"
          >
            <Modal.Header>
              <Modal.Title>Your Title Here</Modal.Title>
            </Modal.Header>
    
            <Modal.Body>
              <Container>
                <PageComponent 
                    selectedPage={selectedPage} 
                    phaseNumber={phase.number}
                    moduleNumber={module.number}
                    handleNextPage={handleNextPage}
                    handlePrevPage={handlePrevPage}
                    addObjectToLocalStorage={addObjectToLocalStorage}
                />
                <Row>
                  <Col xs={12} className="video-button-container">
                  {
                      isLastModuleOfPhase() && isTrueLastPageOfModule(selectedItem, selectedPageIndex)
                      ? <Link className="btn-primary" to="/results">Go to Results</Link>
                      : <Button className="btn-primary" onClick={handleNextPage}>Next</Button>
                  }

                  </Col>
                </Row>
              </Container>
            </Modal.Body>
          </Modal>
        );
      }
      return null;
    }
    
    const renderContent = () => {
      if (selectedPage && isLastPageOfModule(selectedItem, selectedPageIndex)) {
          if (showModal) {
              return renderLastPageModal();
          }
          return null;
      } else {
          return (
            <>
                  <PageComponent 
                      selectedPage={selectedPage} 
                      phaseNumber={phase.number}
                      moduleNumber={module.number}
                      handleNextPage={handleNextPage}
                      handlePrevPage={handlePrevPage}
                      addObjectToLocalStorage={(key, objectToAdd) => addObjectToLocalStorage(key, objectToAdd, selectedPageIndex)}
                  />
                 {
                      selectedPageIndex !== 0 &&
                      <div className="nav-container"><button className="btn-secondary" onClick={handlePrevPage}>Back</button></div>
                  }


                  </>

          );
      }
  };
  const allPages = phase.modules.reduce((acc, currentModule) => { 
    currentModule.items.forEach(currentItem => {
        currentItem.pages.forEach(page => {
            acc.push(page);
        });
    });
    return acc;
}, []);
const selectedIndex = allPages.findIndex(page => page.id === selectedPage?.id);

  return (
    <Layout>
     <Container>
      </Container>
          {module.items.map((item) => (
          <Container className="module-container phase-progress" key={item.id}>
            <Row key={item.id}>
              <Col xs={12} lg={4} className="module-image-container">
              {
                    selectedPage && item.pages.some(page => page.id === selectedPage.id) &&
                    <img src={selectedPage.image} alt="Deloitte" key={selectedPage.id} className="module-image"/>
                } 
              </Col>
              <Col xs={12} lg={8}>
              <Row  className="phase-outline">
              <Col xs={12}>
                <div className='item-container'>
                {
                allPages.map((page, index) => {
                    const pageNumber = index + 1 - Math.floor(index / 3);
                    
                    let pageClass = '';
                    if (index < selectedIndex) {
                        pageClass = 'completed';
                    } else if (index === selectedIndex) {
                        pageClass = 'selected-item';
                    } else {
                      pageClass = 'page';
                    }
                    return (index % 3 === 2)
                        ? <img src={page.roundel} alt="Deloitte" key={page.id} className={`deloitte-value ${pageClass}`} />
                        : <div 
                            key={page.id} 
                            className={`page-div ${pageClass}`}
                          >
                            <p style={{color: "white"}} className="page-item phase-module-item">{pageNumber}</p>
                        </div>
                      })
                    }
                    </div>
                    { renderContent() }
                </Col>
                </Row> 
              </Col>
            </Row>
            </Container>
          ))}
      </Layout>
  )
}

export default ModuleTemplate
