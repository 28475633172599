const page6Data = {
  "id": "86e86ac3-557b-43b3-bf4e-b906615ec71d",
  "number": "6",
  "title": "",
  "image": "https://apifiles.cappassessments.com/Deloitte2023/findyourpath/question5.png",
  "roundel": "https://apifiles.cappassessments.com/Deloitte/lead-the-way.png",
  "content": [
     
  ],
  "afterResponse": [
      ""
  ],

  "resourceType":[
    {
      id: '5be0291e-bfe3-4f8b-8b0c-42f3b3c92ed3',
      name: 'video',
      videoUrl:
        'https://apifiles.cappassessments.com/Deloitte2023/Videos/Video1_Neena_Lead_the_way_v2.mp4',
    },
  ], 

  "responseType": [
  ]
};

module.exports = page6Data;
