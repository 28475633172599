
const TenantIdsPerEnvironment = {
  DEVELOPMENT: '0750dcac-4218-4162-88b1-82f42b02627e',
  TEST: '0750dcac-4218-4162-88b1-82f42b02627e',
  LIVE: '9998a9fd-9050-482e-aa16-b1b35664a7d5',
};

const tenantIdToUse = (() => {
  if (
    TenantIdsPerEnvironment.DEVELOPMENT &&
    typeof window !== 'undefined' &&
    window.environment === 'dev'
  ) {
    return TenantIdsPerEnvironment.DEVELOPMENT;
  } else if (
    TenantIdsPerEnvironment.TEST &&
    typeof window !== 'undefined' &&
    window.environment === 'TEST'
  ) {
    return TenantIdsPerEnvironment.TEST;
  } else {
    return TenantIdsPerEnvironment.LIVE;
  }
})();

const Brand = {
  BRAND_NAME: 'Cappfinity',
  FLAG_NAME: 'Cappfinity',
  TENANT_ID: tenantIdToUse,
  AUID: 'ab047230-207c-4d98-bdaf-3217898762ce',

  HOME_PAGE_TITLE: 'Deloitte Matching tool',
  PAGE1_TITLE: 'Deloitte Matching Tool',

  SIGN_UP_BG: '',
  SIGN_UP_LOGO: '',
  SIGN_UP_BUTTON_COLOR: '',
  SIGN_UP_BUTTON_HOVER: '',
  BUTTON_TEXT_COLOR: '',
  SIGN_UP_HEADER_COLOR: '',
  WELCOME_TEXT: 'Welcome to the Deloitte Matching Tool',
  WELCOME_TEXT_COLOR_ONE: '',
  WELCOME_TEXT_COLOR_TWO: '',
  SIGN_UP_TITLE: 'Sign up to the Deloitte Matching Tool',
  LOG_IN_TITLE: 'Log in to the Deloitte Matching Tool',

  AUTH_CONNECTION: 'Cappfinity-PreparationPlus',
  LOGO_ALT: 'Deloitte Matching Tool',
};

export default Brand;
