const page11Data = {
  "id": "6e312195-8d3c-4771-a7a3-602af0593ab4",
  "number": "11",
  "title": "",
  "image": "https://apifiles.cappassessments.com/Deloitte2023/findyourpath/question8.png",
  "content": [
     
  ],
  "afterResponse": [
      ""
  ],

  "resourceType":[
     
  ], 

  "responseType": [
      {
          "id": "9b71a03c-5abd-4eb2-8069-f9a8d76579d8",
          "name": "quiz",
          "class": "quiz-end",
          "guid": "e8cc4b69-9bac-434a-9381-69bcc1bc22a7",
          "questionText": "Please select 1 answer from the options below",
          "questions": [
            {
              question: 'I want to spend my time:',
              answers: [
                'Thinking creatively',
                'Thinking critically',
                'Preventing problems',
                'Planning for the longer term'
              ],
              showPrevious: false,
                  showFeedback: false,
            },
          ],
          },
  ]  
};

module.exports = page11Data;
