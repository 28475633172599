import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import DashboardLayout from '../components/dashboard-layout';
import Brand from '../Brand';
import { Button, Row, Container, Col } from 'react-bootstrap';
import data from '../data/data';
import deloitteScoring from '../scoring/deloitte-scoring';

function Results() {
  const [responses, setResponses] = useState([]);
  useEffect(() => {
    // Check if localStorage is available before using it
    if (typeof window !== 'undefined') {
      const responsesFound = localStorage.getItem('answers');
      if (responsesFound) {
        const arrayOfSubarrays = JSON.parse(responsesFound);
        const flattenedResponses = [].concat(...arrayOfSubarrays.filter(unit => unit !== null));
        setResponses(flattenedResponses);
      }
    }
  }, []);

  const resetResults = () => {
    // Check if localStorage is available before using it
    if (typeof window !== 'undefined') {
      localStorage.removeItem('answers');
      window.location = '/modules/1';
    }
  };

  const checkAnswers = (array) => {
    // create an object based on the mapping with the names of each area and a totalValue key
    const resultsObject = {};
    Object.keys(deloitteScoring).forEach((key, _index) => {
      // Use the value of subkey as the name of the key in resultsObject
      const name = deloitteScoring[key].area;
      resultsObject[name] = 0;
    });
    // array of all answers by module
    const answersInModule = data.phases.flatMap((phase) =>
      phase.modules.flatMap((module) =>
        module.items.flatMap((item) =>
          item.pages.flatMap((page) =>
            page.responseType.flatMap((response) =>
              response.questions.map((group) => {
                return group.answers ? group.answers : group.categories;
              })
            )
          )
        )
      )
    );

    // map function to check over each answer
    array.map(
      (answer, indexInAnswers) =>
        // index of answer = round of array in mapping to be checked
        {
          const arrayToCheck = Object.keys(deloitteScoring).map(
            (item) => deloitteScoring[item].points[indexInAnswers]
          );

          // get the index of the answer value in the possible answers for the exercise
          const responseToFind = answer.answer;
          //  const indexOfResponseToFind = data.phases[0].modules[indexInAnswers]

          // check the type of object the answers for this module are stored in
          const type = Array.isArray(answersInModule[indexInAnswers]);
          let indexFound = null;
          // if the type is NOT an array, get the key name, otherwise get the value stored at the index of the array
          if (type === false) {
            indexFound = parseInt(answer.value);
          } else {
            indexFound =
              answersInModule[indexInAnswers].indexOf(responseToFind);
          }

          // use this index to get the points value for each area
          const scores = arrayToCheck.map((subArray) => subArray[indexFound]);
          scores.forEach((value, index) => {
            const key = Object.keys(resultsObject)[index];
            resultsObject[key] += value;
          });

          return resultsObject;
        }

      // add this value to the totalValue for each area
      // return the object/three highest values and their areas
      // times by 1.25 to get the percentage
    );
    return resultsObject;
  };

  const sortScores = (array) => {
    const dataArray = Object.entries(array);

    // Sort the array by value in descending order
    dataArray.sort((a, b) => b[1] - a[1]);

    // Initialize variables to store the result
    const result = [];
    const usedValues = new Set();
    const valueCounts = {};

    // Loop through the sorted array and add unique values to the result
    for (const [key, value] of dataArray) {
      if (!usedValues.has(value) && result.length < 3) {
        usedValues.add(value);
        result.push({ key, value });
        valueCounts[value] = 1;
      } else if (valueCounts[value] < 3 && result.length < 3) {
        result.push({ key, value });
        valueCounts[value]++;
      } else {
        return [];
      }

      // If there are exactly 3 unique values in the result, break the loop
      if (result.length === 3) {
        break;
      }
    }

    // Sort the result by value again to ensure it's in descending order
    result.sort((a, b) => b.value - a.value);

    return result;
  };
  
  const totalScores = checkAnswers(responses);
  const topThreeAnswers = sortScores(totalScores);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <DashboardLayout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{Brand.PAGE1_TITLE}</title>
      </Helmet>
      <div
        className="main-window"
        style={{
          margin: `0 auto`,
          width: '100%',
          padding: '0',
        }}
      >
        <Container>
          <Row>
            {loading ? (
              <div className="loading-container">
                <div className="loading">
                  <h1 className="loading-text">We're finding your path...</h1>
                  <div className="loading-bar"></div>
                </div>
              </div>
            ) : (
              <>
                <div className="results-title-container">
                  <h1
                    className="sign-up-title"
                    style={{
                      textAlign: 'left',
                      paddingLeft: '0px',
                      marginTop: '2rem',
                    }}
                  >
                    Your top three matches at Deloitte
                  </h1>
                </div>
                <p style={{ color: 'white', marginBottom: '2rem' }}>
                Hopefully you now have a better understanding of our values, our programmes and the opportunities best suited to your preferences. Here are the career paths we think you'd most enjoy. You can click through to explore more and discover what might be right for you. Please be aware, we close our roles for applications on a rolling basis, so we recommend you get your application in as early as possible. You can only make one application per programme, per academic year (September-August).
                </p>
                <div className='results-container'>
                {topThreeAnswers.map((answer, index) => {
                  const answerData = Object.values(deloitteScoring).find(
                    (entry) => entry.area === answer.key
                  );

                  if (!answerData) {
                    return null;
                  }
                  return (
                    <div key={answer.key} className="result-wrapper">
                      <div className="result-number">
                        <span>{index + 1}</span>
                      </div>
                      <div className="result-info">
                        <h2>{answer.key}</h2>
                        <p>{answerData.content}</p>
                        <a
                          className="btn btn-primary"
                          href={answerData.link}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Explore now
                        </a>
                      </div>
                      <div className="result-percentage">
                        <span>{Math.ceil(answer.value * 1.25)}%</span>
                       
                      </div>
                    </div>
                  );
                })}
                </div>
                <div className="retake-div">
                {  <Button
                    className="btn-secondary"
                    onClick={() => resetResults()}>
                    Retake
                  </Button>}
                </div>
               
                <Container>
                  <Row className="results-values-container">
                   
                  
                    <Col xs={4} lg={2} className='results-value' >
                      <img src="https://apifiles.cappassessments.com/Deloitte2023/findyourpath/collaboration.png" alt="Deloitte Value" />
                      <h4>Collaboration</h4>
                    </Col>
                    <Col xs={4} lg={2} className='results-value' >
                      <img src="https://apifiles.cappassessments.com/Deloitte2023/findyourpath/lead-the-way.png" alt="Deloitte Value" />
                      <h4>Lead the way</h4>
                    </Col>
                    <Col xs={4} lg={2} className='results-value' >
                      <img src="https://apifiles.cappassessments.com/Deloitte2023/findyourpath/integrity.png" alt="Deloitte Value" />
                      <h4>Integrity</h4>
                    </Col>
                    <Col xs={4} lg={2} className='results-value' >
                      <img src="https://apifiles.cappassessments.com/Deloitte2023/findyourpath/care.png" alt="Deloitte Value" />
                      <h4>Care</h4>
                    </Col>
                    <Col xs={4} lg={2} className='results-value' >
                      <img src="https://apifiles.cappassessments.com/Deloitte2023/findyourpath/inclusion.png" alt="Deloitte Value" />
                      <h4>Inclusion</h4>
                    </Col>
                    <p style={{ color: 'white', marginTop: '20px' }}>
                  Our values are really important to us. While you explore your
                  career path opportunities, we'd encourage you to reflect on
                  your own values too, as making sure these align with ours will
                  help you to make the right choice for you.
                </p>
                  </Row>
                </Container>
               
                
              </>
            )}
          </Row>
        </Container>
      </div>
    </DashboardLayout>
  );
}

export default Results;
