import React from 'react';
import PropTypes from 'prop-types';
import Brand from '../Brand';
import Header from './header';
import { useAuth0 } from '@auth0/auth0-react';
import { Helmet } from 'react-helmet';
const DashboardLayout = ({ children }) => {
  const isBrowser = typeof window !== 'undefined';

  const { isAuthenticated} = useAuth0();
  
  return (
    <>
      
      <Helmet
        htmlAttributes={{
          lang: 'en',
          'xmlns:og': 'https://ogp.me/ns#',
          'xmlns:fb': 'https://ogp.me/ns/fb#',
        }}
      >
        <meta charSet="utf-8" />
        <meta content="width=device-width, initial-scale=1.0" name="viewport" />
        ....
      </Helmet>

      <Header isAuthenticated={isAuthenticated} Brand={Brand} />
  
      
      {isBrowser && window.location.pathname === '/' && (
        
        <main style={{
          margin: `0 auto`,
          width: '100%',
          padding: '0',
          background: "black",
          
        }}>{children}</main>
         
        
      )}
      {isBrowser && window.location.pathname === '/testing-sign-up' && (
    
          <main>{children}</main>
         
  
      )}

      {isBrowser && window.location.pathname !== '/' && (
        <div
          style={{
            margin: `0 auto`,
            width: '100%',
            padding: '0',
          }}
        >
          <main style={{
            margin: `0 auto`,
            width: '100%',
            padding: '0',
          }}>
            
            
            
       
                  {children}

       
          </main>
        </div>
      )}
      
      
    </>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DashboardLayout;
