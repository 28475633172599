import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import React, { useState } from 'react';
import RangeSlider from 'react-bootstrap-range-slider';
import { Button } from 'react-bootstrap';
import Feedback from '@capp.micro/shared/components';
import PropTypes from 'prop-types';

const checkAnswers = (questions, responseToCheck) => {
  if (questions[0].feedback) {
    if (questions[0].feedback[1]) {
      if (
        parseInt(responseToCheck.answer) >= questions[0].feedback[1].range[0] &&
        parseInt(responseToCheck.answer) <= questions[0].feedback[1].range[1]
      ) {
        responseToCheck.feedback = questions[0].feedback[1].text;
        return responseToCheck;
      } else {
        responseToCheck.feedback = questions[0].feedback[2].text;
        return responseToCheck;
      }
    } else {
      responseToCheck.feedback = questions[0].feedback.defaultFeedback;
      return responseToCheck;
    }
  } else {
    return responseToCheck;
  }
};

export const SliderQuestion = ({
  questions, addObjectToLocalStorage
}) => {

  SliderQuestion.propTypes = {
    questions: PropTypes.array,
    addObjectToLocalStorage: PropTypes.func
  }

  const [value, setValue] = useState(questions[0].startingValue);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [responses, setResponses] = useState([]);

  const showNav = () => {
    return currentQuestion === questions[0].questions.length - 1 ? (
      <div>
        <Button onClick={() => handleClick('submit')} id="slider-next">
          {' '}
          Submit
        </Button>
      </div>
    ) : (
      <div>
        <Button onClick={() => handleClick('next')} id="slider-next">
          {' '}
          Next
        </Button>
      </div>
    );
  };

  const showFunction = (displayResponses) => {
    if (questions.length > 0 && questions[0].questions.length > 0) {
      if (currentQuestion < questions[0].questions.length) {
        return showMainFunction();
      } else {
        return displayResponses(responses);
      }
    } else {
      return <div>No questions to display</div>;
    }
  };

  const showMainFunction = () => {
    return (
      <>
        <div className="slider-header">
          <div className="slider-questions">
            Question {currentQuestion + 1} of{' '}
            {Object.keys(questions[0].questions).length}{' '}
          </div>
          <div className="slider-prompt">
            Please drag the slider to any position along the scale. You may drag
            the slider to any position you like, but please make sure that you
            either click on it or move it from its starting position first.
          </div>
          <div className="slider-questions-individual">
            {questions[0].questions[currentQuestion]}
          </div>
        </div>
        <RangeSlider
          value={value}
          onChange={(e) => setValue(e.target.value)}
          step={questions[0].increments}
          tooltip={
            'off'
          }
          min={questions[0].min}
          max={questions[0].max}
        />
        <div className="slider-footer">
          <div className="slider-categories">
            {Object.keys(questions[0].categories).length !== 0
              ? Object.entries(questions[0].categories).map((category) => category[1].length > 0 ? (
                <div
                  className="slider-categories-individual"
                  key={questions[category]}
                >
                  {category.pop()}
                </div>
              ) : (''))
              : ''}
          </div>
          <div className="slider-nav">
            <div>
              <Button onClick={() => handleClick('back')} id="slider-back">
                Back to previous question
              </Button>
            </div>

            {showNav()}
          </div>
        </div>
      </>
    );
  };

  const getFeedbackForResponse = (response) => {
    for (const object of Object.keys(questions[0].feedback)) {
      if (
        parseInt(response.answer) >= questions[0].feedback[object].range[0] &&
        parseInt(response.answer) <= questions[0].feedback[object].range[1]
      ) {
        return questions[0].feedback[object].text;
      }
    }
    return null;
  };
  
  const displayAnswerText = (response) => {
    if (response.answer && response.answer !== response.value) {
      return `${response.answer} (${response.value})`;
    }
    return response.value;
  };
  
  const showResponses = (responsesToShow) => {
    if (!questions[0].showFeedback) {
      setCurrentQuestion((prevQuestion) => prevQuestion - 1);
      return showMainFunction();
    }
  
    const feedbackMapped = responsesToShow.map((response, index) => {
      if (questions[0].feedback[1]) {
        const feedbackText = getFeedbackForResponse(response);
        if (!feedbackText) {
          return null;
        }
  
        return (
          <div key={response.answer} style={{ margin: '20px 0px' }}>
            <div>
              Question {index + 1}: {response.question} <br />
              You responded: {response.answer}%
            </div>
            {feedbackText}
          </div>
        );
      } else {
        return (
          <div key={response.answer} style={{ margin: '20px 0px' }}>
            <div>
              Question {index + 1}: {response.question} <br />
              You responded: {displayAnswerText(response)}
            </div>
            {response.feedback}
          </div>
        );
      }
    });
  
    return <Feedback feedbackText={feedbackMapped} />;
  };
  

  const handleClick = (action) => {
    const addResponse = () => {
      const newResponse = {
        question: questions[0].questions[currentQuestion],
        answer:
          Object.keys(questions[0].categories).length !== 0
            ? questions[0].categories[value]
            : value,
        value: value,
      };
      checkAnswers(questions, newResponse);
      return [...responses, newResponse];
    };

    if (action === 'back' && currentQuestion !== 0) {
      setCurrentQuestion((prevQuestion) => prevQuestion - 1);
      setResponses((prevResponses) => prevResponses.slice(0, -1));
      setValue(questions[0].startingValue);
    } else if (
      action === 'next' &&
      currentQuestion < questions[0].questions.length
    ) {
      const newResponses = addResponse();
      setResponses(newResponses);

      setCurrentQuestion((prevQuestion) => prevQuestion + 1);
      setValue(questions[0].startingValue);
    } else if (action === 'submit') {
      setCurrentQuestion((prevQuestion) => prevQuestion + 1);
      const answers = addResponse();
      setResponses(answers);
      addObjectToLocalStorage("answers", answers
      );
    } else {
      return;
    }
  };

  return (
    <div className="slider-function">

        {showFunction(showResponses)}

    </div>
  );
};

export default SliderQuestion;
