const page15Data = {
  "id": "b9d5b350-d511-4db0-a810-cdaef922df28",
  "number": "15",
  "title": "",
  "image": "https://apifiles.cappassessments.com/Deloitte2023/findyourpath/question10.png",
  "roundel": "https://apifiles.cappassessments.com/Deloitte/inclusion.png",
  "content": [
     
  ],
  "afterResponse": [
      ""
  ],

  "resourceType":[
    {
      id: 'a69f6001-9b9f-4133-a587-6ca3f35129b2',
      name: 'video',
      videoUrl: 'https://apifiles.cappassessments.com/Deloitte2023/Videos/Video4_Motaz_Foster_inclusion.mp4',
    },
  ], 
 
  "responseType": [
  ]
};

module.exports = page15Data;
