const deloitteScoring = {
    1: {
        area: "Actuarial", 
        points: [[4,5,5,4], [6,6,7,8], [8,7,6,6], [2,4,6,8], [8,6,4,2], [8,6,2,6], [6,8,6,9], [4,8,6,6], [6,9,2,6], [6,9,9,9]],
        image: "actuarial.png",
        content: "In Actuarial we are problem solvers and strategic thinkers, who use mathematical skills to help measure the probability and risk of future events. Our work helps individuals to plan for the future, and it’s always challenging and interesting.",
        link: "https://www2.deloitte.com/uk/en/pages/careers/articles/early-careers-human-capital-and-actuarial.html"
    },
    2: {
        area: "Audit", 
        points: [[4,5,5,4], [2,4,7,9], [9,8,7,6], [2,4,6,8], [9,7,4,2], [9,4,4,8], [6,7,2,7], [2,7,7,4], [4,8,4,9], [6,8,6,8]],
        image: "audit-assurance.png",
        content: "As the world changes, businesses must be ready to respond to the challenges and opportunities that lie ahead. In Audit & Assurance we support clients to operate as they should, building public confidence and trust.",
        link: "https://www2.deloitte.com/uk/en/pages/careers/articles/student-in-audit-finance.html"
    },
    3: {
        area: "Business & Financial Advisory", 
        points: [[8,7,6,4], [8,7,6,4], [2,5,8,9], [6,7,7,8], [2,4,7,8], [6,8,8,4], [4,0,2,6], [9,8,6,6], [5,6,8,4], [8,6,2,6]],
        image: "business-financial.png",
        content: "Business & Financial Advisory is all about finding the smartest solutions to the most complex challenges. From helping struggling businesses to shine again, to combatting financial crime – you can do it all here.",
        link: "https://www2.deloitte.com/uk/en/pages/careers/articles/early-careers-business-financial-advisory.html"
    },
    4: {
        area: "Consulting", 
        points: [[8,7,6,4], [8,7,6,4], [2,5,7,8], [6,7,7,8], [2,4,7,8], [6,7,8,4], [4,0,2,5], [9,8,6,6], [4,6,9,4], [9,6,2,6]],
        image: "consulting.png",
        content: "We are helping our clients to lead the way for change, and future-proof their businesses. From delivering new tech solutions to leading operational transformations, you’ll make your mark from day one.",
        link: "https://www2.deloitte.com/uk/en/pages/careers/articles/early-careers-consulting.html"
    },
    5: {
        area: "Cyber, Data & Digital", 
        points: [[4,4,5,5], [6,6,7,8], [8,6,3,0], [8,7,6,4], [8,7,6,5], [8,8,6,9], [9,8,0,4], [4,8,9,8], [9,6,2,8], [4,7,7,7]],
        image: "cyber.png",
        content: "The more virtual our world becomes, the more real the cyber risks are. We support our clients to stay secure and protect valuable assets. You’ll work alongside our diverse teams to build your career your way.",
        link: "https://www2.deloitte.com/uk/en/pages/careers/articles/early-careers-cyber.html"
    },
    6: {
        area: "Enabling Functions", 
        points: [[0,4,8,9], [4,5,7,8], [8,6,2,0], [8,7,5,4], [8,7,6,4], [8,4,6,8], [8,9,2,6], [4,6,8,4], [7,6,2,8], [4,6,6,6]],
        image: "enabling-functions.avif",
        content: "Deloitte is helping businesses to shape the world. In Enabling Functions we support our colleagues to do that well, so the firm can continue to make a positive impact for clients and wider society. Join us to work across a wide range of different areas.",
        link: "https://www2.deloitte.com/uk/en/pages/careers/articles/student-enabling-functions.html"
    },
    7: {
        area: "Legal", 
        points: [[5,6,6,5], [4,5,7,8], [9,8,6,4], [4,5,7,8], [9,8,6,4], [8,6,4,8], [4,7,6,2], [4,8,8,6], [6,4,2,9], [6,6,6,8]],
        image: "legal.png",
        content: "Through our innovation and vast expertise, we are reshaping the future of legal services. And that’s exactly what you’ll be doing at Deloitte. When the right people come together, there’s no limit to what we can achieve.",
        link: "https://www2.deloitte.com/uk/en/pages/careers/articles/early-careers-deloitte-legal.html"
    },
    8: {
        area: "Real Estate", 
        points: [[7,6,6,5], [4,5,7,8], [9,8,7,6], [0,4,8,9], [8,6,4,2], [9,4,4,4], [2,8,9,2], [2,6,6,9], [4,4,2,7], [5,4,2,5]],
        image: "real-estate.avif",
        content: "We’re utilising our skills and the power of technology to redefine the concept of real estate. Whether that’s regenerating an area of land or advising corporate property owners, you’ll offer invaluable services to clients.",
        link: "https://www2.deloitte.com/uk/en/pages/careers/articles/early-careers-real-estate.html"
    },
    9: {
        area: "Risk Advisory", 
        points: [[7,6,6,5], [2,5,8,9], [9,8,7,6], [4,6,8,9], [8,8,7,7], [8,8,6,8], [3,5,3,3], [6,9,9,8], [6,6,6,8], [6,8,9,8]],
        image: "risk-advisory.png",
        content: "For any organisation, failure to navigate uncertainty can cause challenges. That's why we help our clients to take action when needed. As well as supporting with compliance, you’ll be transforming how businesses work.",
        link: "https://www2.deloitte.com/uk/en/pages/careers/articles/early-careers-governance-risk-regulation.html"
    },
    10: {
        area: "Tax Consulting", 
        points: [[5,6,6,5], [4,6,7,8], [9,8,7,7], [2,5,8,9], [9,8,6,4], [8,8,4,6], [6,8,4,8], [7,7,7,7], [6,8,6,8], [8,8,6,8]],
        image: "tax-consulting.png",
        content: "Innovation. Creative thinking. Solving complex challenges. If you thought a career in tax was all about filing returns, think again. You’ll be doing challenging, meaningful work from your first day in the job, making an impact that matters.",
        link: "https://www2.deloitte.com/uk/en/pages/careers/articles/early-careers-tax-consulting.html"
    },
    11: {
        area: "Technology", 
        points: [[6,7,7,6], [7,6,6,5], [7,6,3,0], [2,5,8,9], [9,8,6,4], [7,8,6,7], [9,8,0,4], [6,8,6,6], [8,8,2,4], [4,8,6,6]],
        image: "technology.png",
        content: "If you believe the world can work better, you’re not alone. We have a real drive for change, and we’re leading the way through new technology-centered solutions. By joining us you’ll be helping to transform industries.",
        link: "https://www2.deloitte.com/uk/en/pages/careers/articles/early-careers-technology.html"
    },
}

export default deloitteScoring