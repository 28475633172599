import React from 'react';
import PropTypes from 'prop-types';



export const VideoContent = ({ videoUrl, text }) => {

  VideoContent.propTypes = {
    videoUrl: PropTypes.string,
    text: PropTypes.string
  }
 

  return (
    <div>
      <video width="320" height="240" controls autoPlay>
        <source src={videoUrl} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <p>{text}</p>
    </div>
  );
}

export default VideoContent;
