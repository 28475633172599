const page5Data = {
  "id": "229583fd-3ec0-40b8-bc2e-cad8bbdc7bc4",
  "number": "5",
  "title": "",
  "image": "https://apifiles.cappassessments.com/Deloitte2023/findyourpath/question4-2.png",
  "content": [
     
  ],
  "afterResponse": [
      ""
  ],

  "resourceType":[
     
  ], 

  "responseType": [
      {
          "id": "d9b4b8d2-713f-4fde-837e-48c74bdc2ff6",
          "name": "sliderQuestion",
          "class": "slider-container",
          "guid": "aea1ae14-45ce-4ac7-81e9-bb96447c762b",
          "questionText": "",
          "questions": [
              {
                  questions: [
                    'I would prefer to work:',
                  ],
                  categories: {0: 'Under immediate pressure, with a focus on quicker results', 1: '', 2: '', 3: 'With a longer term perspective on future plans'},
                  max: 3,
                  min: 0,
                  startingValue: 0,
                  increments: 1,
                  showPreviousAnswers: false,
                  showFeedback: false,
                },
          ],
          },
  ]  
};

module.exports = page5Data;
