const page8Data = {
  "id": "e562476e-0607-4d10-942d-09ebc89bad8c",
  "number": "8",
  "title": "",
  "image": "https://apifiles.cappassessments.com/Deloitte2023/findyourpath/question6.png",
  "content": [
     
  ],
  "afterResponse": [
      ""
  ],

  "resourceType":[
     
  ], 

  "responseType": [
      {
          "id": "9d311166-7f2c-4a13-8752-526db3425642",
          "name": "quiz",
          "class": "quiz-end",
          "guid": "cb0c21f8-d9f2-401d-b3fa-53673d02f232",
          "questionText": "Please select 1 answer from the options below",
          "questions": [
            {
              question: 'The most important thing to me is:',
              answers: [
                'Giving confidence and assurance',
                'Solving challenging problems',
                'Making positive change',
                'Ensuring safety and security'
              ],
              showPrevious: false,
                  showFeedback: false,
            },
          ],
          },
  ]  
};

module.exports = page8Data;
