const page4Data = {
    "id": "b00b5730-7d0c-4601-9170-0d62549fb688",
    "number": "4",
    "title": "",
    "image": "https://apifiles.cappassessments.com/Deloitte2023/findyourpath/question3.png",
    "content": [
       
    ],
    "afterResponse": [
        ""
    ],

    "resourceType":[
       
    ], 

    "responseType": [
        {
            "id": "59cab68f-a1ba-488a-ac7c-7d220548e8a3",
            "name": "sliderQuestion",
            "class": "slider-container",
            "guid": "6f715b4f-b2ac-4b03-ad13-289fbdf43bd8",
            "questionText": "",
            "questions": [
                {
                    questions: [
                      'I like working with:',
                    ],
                    categories: {0: 'Compliance, governance and trust', 1: '', 2: '', 3: 'Business, commercials and financial growth'},
                    max: 3,
                    min: 0,
                    startingValue: 0,
                    increments: 1,
                    showPrevious: false,
                  showFeedback: false,
                
                  },
            ],
            },
    ]  
};

module.exports = page4Data;
