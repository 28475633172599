const page13Data = {
    "id": "7d333fa9-71d7-43ab-b9bf-f019277ec81f",
    "number": "13",
    "title": "",
    "image": "https://apifiles.cappassessments.com/Deloitte2023/findyourpath/question9.png",
    "content": [
       
    ],
    "afterResponse": [
        ""
    ],

    "resourceType":[
       
    ], 

    "responseType": [
      {
        "id": "d90b171c-5616-4223-b1af-51c15f5bb0fe",
        "name": "quiz",
        "class": "quiz-end",
        "guid": "e2e5b8bd-ce5c-44ed-8ee0-5bb762162c63",
        "questionText": "Please select 1 answer from the options below",
        "questions": [
          {
            question: 'I want my career to be about:',
            answers: [
              'Software and technology',
              'Data and analytics',
              'People and business',
              'Rules and compliance'
            ],
            showPrevious: false,
                  showFeedback: false,
          },
        ],
        },
    ]  
};

module.exports = page13Data;
