const module1Data = require('./modules/1/module1');
const phase1Data = {
    id: 1,
    number: "1",
    name: "Discover",
    image: "https://apifiles.cappassessments.com/90DTK/phase1-2.png",
    description: "In this phase you will discover your strengths, discover the organisational culture, discover who is in your support network and discover how to make a great first impression.",
    outcomeType: "freeText",
    modules: [module1Data]
};

module.exports = phase1Data;