const page9Data = {
  "id": "7677f08c-738e-4874-afb8-d716432bcfa6",
  "number": "9",
  "title": "",
  "image": "https://apifiles.cappassessments.com/Deloitte2023/findyourpath/question7-2.png",
  "roundel": "https://apifiles.cappassessments.com/Deloitte/integrity.png",
  "content": [
     
  ],
  "afterResponse": [
      ""
  ],

  "resourceType":[
    {
      id: 'a28a22b0-fca1-40b2-bee6-4b43e6ef747f',
      name: 'video',
      videoUrl:
        'https://apifiles.cappassessments.com/Deloitte2023/Videos/Video2_Destiny_Serve.mp4',
    },
  ], 

  "responseType": [
  ]
};

module.exports = page9Data;
