const item1Data = require('./items/item1.js');
const module1Data = {
    id: 1,
    number: "1",
    name: "Discovering your strengths",
    description: "Explore strengths and how to recognise them",
    items: [item1Data],
    image: "https://apifiles.cappassessments.com/Deloitte/module2.png"
};

module.exports = module1Data;
