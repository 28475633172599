const page10Data = {
    "id": "28949062-b66f-4e52-b16d-9767f74aeb5c",
    "number": "10",
    "title": "",
    "image": "https://apifiles.cappassessments.com/Deloitte2023/findyourpath/question7-2.png",
    "content": [
       
    ],
    "afterResponse": [
        ""
    ],

    "resourceType":[
       
    ], 

    "responseType": [
      {
        "id": "dc638135-74b4-423f-92c6-b62f64f61e35",
        "name": "quiz",
        "class": "quiz-end",
        "guid": "97a7ad3c-606b-4930-b682-b01f67f0d8b0",
        "questionText": "Please select 1 answer from the options below",
        "questions": [
          {
            question: 'My dream job would involve:',
            answers: [
              'Working with technology',
              'Supporting others in the background',
              'Working with property and real estate',
              'Working with numbers and statistics'
            ],
            showPrevious: false,
                  showFeedback: false,

          },
        ],
        },
    ]  
};

module.exports = page10Data;
