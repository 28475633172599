const page1Data = {
    "id": "04ef18b8-c3ea-4499-8fac-4b11934bbca9",
    "number": "1",
    "title": "",
    "image": "https://apifiles.cappassessments.com/Deloitte2023/findyourpath/question1.png",
    "content": [
       
    ],
    "afterResponse": [
        ""
    ],

    "resourceType":[
        {
        "name": "intro",
        }
    ], 

    "responseType": [
        {
            "id": "60a64b21-c92b-4db8-8dab-eda2be1f9b46",
            "name": "sliderQuestion",
            "class": "slider-container",
            "guid": "9261eef0-e94c-4906-8ae7-93ee281ca0ff",
            "questionText": "",
            "questions": [
                {
                    questions: [
                      'I would prefer:',
                    ],
                    categories: {0: 'Collaborating with new people from outside of Deloitte', 1: '', 2: '', 3: 'Collaborating with the teams inside of Deloitte'},
                    max: 3,
                    min: 0,
                    startingValue: 0,
                    increments: 1,
                    showPrevious: false,
                    showFeedback: false,
                   
                  },
            ],
            },
    ]  
};

module.exports = page1Data;
