const page12Data = {
  "id": "2dcbf96d-c1e1-40b7-8a14-878c5cd6f954",
  "number": "12",
  "title": "",
  "image": "https://apifiles.cappassessments.com/Deloitte2023/findyourpath/question9.png",
  "roundel": "https://apifiles.cappassessments.com/Deloitte/care.png",
  "content": [
     
  ],
  "afterResponse": [
      ""
  ],

  "resourceType":[
    {
      id: 'af8a6540-be00-4b4a-8cd9-2307289e5ee4',
      name: 'video',
      videoUrl:
        'https://apifiles.cappassessments.com/Deloitte2023/Videos/Video3_Jenny_Take_care_v2.mp4',
    },
  ], 

  "responseType": [
  ]
};

module.exports = page12Data;
