// eslint-disable-next-line
const page1Data = require('./pages/page1');
const page2Data = require('./pages/page2');
const page3Data = require('./pages/page3');
const page4Data = require('./pages/page4');
const page5Data = require('./pages/page5');
const page6Data = require('./pages/page6');
const page7Data = require('./pages/page7');
const page8Data = require('./pages/page8');
const page9Data = require('./pages/page9');
const page10Data = require('./pages/page10');
const page11Data = require('./pages/page11');
const page12Data = require('./pages/page12');
const page13Data = require('./pages/page13');
const page14Data = require('./pages/page14');
const page15Data = require('./pages/page15');
const item1Data = {
    "id": "a9f4e0ac-1ff7-4e03-8df6-ca0e12243354",
    "title": "Discovering your strengths",
    "description": "Explore strengths and how to recognise them",
    pages: [page1Data,page2Data,page3Data,page4Data,page5Data,page6Data,page7Data,page8Data,page9Data,page10Data,page11Data,page12Data,page13Data,page14Data,page15Data]
};

module.exports = item1Data;
