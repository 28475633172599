import React from 'react';
import { Helmet } from 'react-helmet';
import DashboardLayout from '../components/dashboard-layout';
import Brand from '../Brand';
import {Col, Row, Container} from 'react-bootstrap';
import { Link } from 'gatsby';

function Dashboard() {

  return (
    <DashboardLayout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{Brand.PAGE1_TITLE}</title>
      </Helmet>
      <div className='main-window' style={{
        margin: `0 auto`,
        height: '90vh',
        width: '100%',
        padding: '0',
      }}>
          <Container>
            <Row>
              <Col xs={12} lg={6} className="text-container">
            <div className="text">
            <h1 className="sign-up-title" style={{backgroundColor: "transparent", paddingLeft: "0px"}}>
            Find your fit
            </h1>
            <p>Welcome to the Matching Tool, which we've created to help match you to suitable roles at Deloitte.</p>

              <Link className="btn btn-primary" to={`/modules/1`}>Start now</Link>

            </div>
          </Col>
            </Row>
        </Container>
      </div>
 
    </DashboardLayout>
  );
}

export default Dashboard;
