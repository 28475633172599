const page7Data = {
    "id": "0c5c4676-515b-4adb-8e4c-8783ec85bb18",
    "number": "7",
    "title": "",
    "image": "https://apifiles.cappassessments.com/Deloitte2023/findyourpath/question5.png",
    "content": [
       
    ],
    "afterResponse": [
        ""
    ],

    "resourceType":[
       
    ], 

    "responseType": [
        {
            "id": "fc03b40d-f273-49ee-8718-742b861f6c19",
            "name": "sliderQuestion",
            "class": "slider-container",
            "guid": "d5e35652-2800-49a9-8c4d-1118f8c7dbe9",
            "questionText": "",
            "questions": [
                {
                    questions: [
                      'I am more interested in:',
                    ],
                    categories: {0: 'Following processes and procedures', 1: '', 2: '', 3: 'Building relationships with people and experiencing change'},
                    max:3,
                    min: 0,
                    startingValue: 0,
                    increments: 1,
                    showPrevious: false,
                  showFeedback: false,
                  },
            ],
            },
    ]  
};

module.exports = page7Data;
