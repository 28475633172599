const page14Data = {
  "id": "23dacfb8-4ec2-483f-87c0-0f79bf48b938",
  "number": "14",
  "title": "",
  "image": "https://apifiles.cappassessments.com/Deloitte2023/findyourpath/question10.png",
  "content": [
     
  ],
  "afterResponse": [
      ""
  ],

  "resourceType":[
     
  ], 

  "responseType": [
      {
          "id": "0f4ad492-074b-4f5c-b3db-f1fca7fadd8f",
          "name": "quiz",
          "class": "quiz-end",
          "guid": "f53203a6-2c0a-46f4-92b0-ad6f32880196",
          "questionText": "Please select 1 answer from the options below",
          "questions": [
            {
              question: 'The thing that makes me most excited is:',
              answers: [
                'Working with commercials and financial growth',
                'Delivering technical quality',
                'Modelling risks',
                'Getting things right every time'
              ],
              showPrevious: false,
              showFeedback: false,
            },
          ],
          },
  ]  
};

module.exports = page14Data;
