import React from 'react';
import PropTypes from 'prop-types';
import Brand from '../Brand';
import Header from './header';
import { Helmet } from 'react-helmet';
const Layout = ({ children }) => {
  const isBrowser = typeof window !== 'undefined';

  return (
    <>
      
      <Helmet
        htmlAttributes={{
          lang: 'en',
          'xmlns:og': 'https://ogp.me/ns#',
          'xmlns:fb': 'https://ogp.me/ns/fb#',
        }}
      >
        <meta charSet="utf-8" />
        <meta content="width=device-width, initial-scale=1.0" name="viewport" />
        ....
      </Helmet>

      <Header Brand={Brand} />
  
      
      {isBrowser && window.location.pathname === '/' && (
        
          <main>{children}</main>
         
        
      )}
      {isBrowser && window.location.pathname === '/testing-sign-up' && (
    
          <main>{children}</main>
         
  
      )}

      {isBrowser && window.location.pathname !== '/' && (
        <div
          style={{
            margin: `0 auto`,
            width: '100%',
            padding: '0',
            background: "black",
            display: "flex",
            alignItems: "center",
            height: "45vh",
          }}
        >
          <main style={{
            margin: `0 auto`,
            width: '100%',
            padding: '0',
            background: "black",
           
          }}>
            
            
            
            
                
                  {children}

          </main>
        </div>
      )}
      
      
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;