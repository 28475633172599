import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
import Brand from '../Brand';
import {
  ImageContent,
  TextContent
} from '@capp.micro/shared/components';
import SliderQuestion from './sliderQuestion';
import Quiz from './quiz';
import VideoContent from './videoContent';
import PropTypes from 'prop-types';

const ResponseRenderer = ({
  name,
  brand,
  user,
  getAccessTokenSilently,
  phaseNumber,
  moduleNumber,
  pageNumber,
  setUserMetadata,
  addObjectToLocalStorage,
  ...props
}) => {

  ResponseRenderer.propTypes = {
    name: PropTypes.string,
    brand: PropTypes.object, 
    user: PropTypes.object, 
    getAccessTokenSilently: PropTypes.func, 
    phaseNumber: PropTypes.string, 
    moduleNumber: PropTypes.string, 
    pageNumber: PropTypes.string,
    setUserMetadata: PropTypes.func,
    addObjectToLocalStorage: PropTypes.func
  }

  const RESPONSE_COMPONENT_MAP = {
    quiz: Quiz,
    sliderQuestion: SliderQuestion,
  };

  const ComponentToRender = RESPONSE_COMPONENT_MAP[name];

  if (!ComponentToRender) {
    return null;
  }

  return (
    <ComponentToRender
      {...props}
      brand={brand}
      user={user}
      getAccessTokenSilently={getAccessTokenSilently}
      phaseNumber={phaseNumber}
      moduleNumber={moduleNumber}
      pageNumber={pageNumber}
      setUserMetadata={setUserMetadata}
      addObjectToLocalStorage={addObjectToLocalStorage}
    />
  );
};

const ContentRenderer = ({
  title, content, summary, action, resourceType, responseType, moreResources, setUserMetadata, brand, user, getAccessTokenSilently, phaseNumber, moduleNumber, pageNumber,
  handleNextPage, addObjectToLocalStorage, ...props
}) => {
  ContentRenderer.propTypes = {
    title: PropTypes.string,
    content: PropTypes.array,
    afterResponse: PropTypes.array,
    summary: PropTypes.object,
    action: PropTypes.array,
    time: PropTypes.string,
    resourceType: PropTypes.array,
    responseType: PropTypes.array,
    moreResources: PropTypes.array,
    setUserMetadata: PropTypes.func, 
    brand: PropTypes.object, 
    user: PropTypes.object, 
    getAccessTokenSilently: PropTypes.func, 
    phaseNumber: PropTypes.string, 
    moduleNumber: PropTypes.string, 
    pageNumber: PropTypes.string, 
    handleNextPage: PropTypes.func,
    addObjectToLocalStorage: PropTypes.func
  }

  const [modalShow, setModalShow] = React.useState(false);
  const TYPE_COMPONENT_MAP = {
    video: VideoContent,
    image: ImageContent,
    text: TextContent,
  };

  
  useEffect(() => {
    const allButtons = document.querySelectorAll('button');
    const submitButton = Array.from(allButtons).find(button => button.innerText === 'Submit');
    if (submitButton) {
      submitButton.addEventListener('click', (_event) => {
        // Allow the default behavior to run
        setTimeout(() => {
          // Perform your custom logic after a brief delay
          handleNextPage();
        }, 3);
      });
    }
  }, [pageNumber, handleNextPage]);


  return (
    <>
      <h1>{title}</h1>
      <div className="resource-container">
        {resourceType?.map((resource, _index) => {
          const ComponentToRender = TYPE_COMPONENT_MAP[resource.name];
          if (!ComponentToRender) {
            return null;
          }
          return (
            <ComponentToRender
              key={resource.id}
              {...resource}
              {...props}
              brand={brand}
              user={user}
              getAccessTokenSilently={getAccessTokenSilently}
              phaseNumber={phaseNumber}
              moduleNumber={moduleNumber}
              pageNumber={pageNumber}
              setUserMetadata={setUserMetadata}
              id={resource.id}
            />
          );
        })}
      </div>

      {content?.map((text) => (
        <div key={text.text} className="content">
          <p className={text.class}>{text.text}</p>
          {text.image && <img src={text.image.src} alt={text.image.alt} />}
          {text.list && (
            <ul className="content-list">
              {text.list.map((item) => (
                <li key={item}>{item}</li>
              ))}
            </ul>
          )}
        </div>
      ))}

      <div className="response-container">
        {responseType?.map((response) => {
          if (response.type === 'content') {
            return (
              <div key={response.id} className="in-between-content">
                {response.text}
              </div>
            );
          } else {
            return (
              <div key={response.id} className={response.class}>
                <h2 className="question-header">{response.questionHeader}</h2>
                <h2 className="question-text">{response.questionText}</h2>
                <ResponseRenderer
                  {...response}
                  {...props}
                  brand={brand}
                  user={user}
                  getAccessTokenSilently={getAccessTokenSilently}
                  phaseNumber={phaseNumber}
                  moduleNumber={moduleNumber}
                  pageNumber={pageNumber}
                  setUserMetadata={setUserMetadata}
                  addObjectToLocalStorage={addObjectToLocalStorage}
                />
               
              </div>
            );
          }
        })}
      </div>

      <div className="after-response">

      </div>

      {summary?.map((text) => (
        <div key={text.text} className="content">
          <p className={text.class}>{text.text}</p>
          {text.image && <img src={text.image.src} alt={text.image.alt} />}
          {text.list && (
            <ul className="content-list">
              {text.list.map((item) => (
                <li key={item}>{item}</li>
              ))}
            </ul>
          )}
        </div>
      ))}

      {moreResources?.map((resource) => (
        <Container key={resource.id} className="more-resources-container">
          <Row>
            <Col xs={12}>
              {' '}
              <h2>{resource.name}</h2>
            </Col>
          </Row>
          <Row>
            {resource.items?.map((item) => (
              <Col xs={12} lg={4} key={item.id}>
                <div
                  key={item.id}
                  className={`${item.class} recommended-tile`}
                >
                  <div className="recommended-text">
                    <h2>{item.heading}</h2>
                    <p>{item.name}</p>
                  </div>
                  <div className="recommended-button">
                    <a
                      className="btn btn-primary"
                      href={item.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {item.linkText}
                    </a>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      ))}
      {action?.map((text) => (
        <div key={text} className="action-nav">
          <button
            className="introduction-video btn-primary"
            onClick={() => setModalShow(true)}
          >
            Action
          </button>
          <Modal
            show={modalShow}
            onHide={() => setModalShow(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            className="action-modal"
          >
            <Modal.Header closeButton>
              <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Container>
                <Row>
                  <Col xs={12}>
                    <div className="page-action">{text}</div>
                  </Col>
                </Row>
              </Container>
            </Modal.Body>
          </Modal>
        </div>
      ))}
    </>
  );
};


export const PageComponent = ({
  selectedPage,
  setUserMetadata,
  phaseNumber,
  moduleNumber,
  handlePrevPage,
  handleNextPage,
  handleCompletion,
  addObjectToLocalStorage
}) => {
  PageComponent.propTypes = {
    selectedPage: PropTypes.object,
    setUserMetadata: PropTypes.func,
    phaseNumber: PropTypes.string,
    moduleNumber: PropTypes.string,
    handlePrevPage: PropTypes.func,
    handleNextPage: PropTypes.func,
    handleCompletion: PropTypes.func,
    addObjectToLocalStorage: PropTypes.func
  }

  const [showVideoModal, setShowVideoModal] = React.useState(false);
  useEffect(() => {
    if (selectedPage?.resourceType?.some(resource => resource.name === 'intro')) {
      setShowVideoModal(true);
    }    
  }, [selectedPage]);
  
  const { user, getAccessTokenSilently } = useAuth0();
  if (!selectedPage) {
    return <p>No item selected</p>;
  }
 
  return (
    <Container className="page-container">
   
      <Modal
            show={showVideoModal}
            onHide={() => setShowVideoModal(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            className="module-modal"
          >
            <Modal.Header>
              <Modal.Title></Modal.Title>
            </Modal.Header>
    
            <Modal.Body>
              <Container>
                  <video width="320" height="240" controls autoPlay>
                    <source src="https://apifiles.cappassessments.com/Deloitte2023/Videos/Deloitte_Matching_tool_Intro.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <Row>
                  <Col xs={12} className="video-button-container">
                    
                  <Button className="btn-primary" onClick={() => setShowVideoModal(false)}>Continue</Button>

                
                  </Col>
                </Row>
              </Container>
            </Modal.Body>
          </Modal>

      <Row>
        <Col xs={12}>
          <div className="dashboard-intro" style={{ height: 'auto' }}>
            <div>
              <ContentRenderer
                {...selectedPage}
                brand={Brand}
                getAccessTokenSilently={getAccessTokenSilently}
                user={user}
                setUserMetadata={setUserMetadata}
                phaseNumber={phaseNumber}
                moduleNumber={moduleNumber}
                pageNumber={selectedPage.number}
                handleCompletion={handleCompletion}
                handleNextPage={handleNextPage}
                handlePrevPage={handlePrevPage}
                addObjectToLocalStorage={addObjectToLocalStorage}
              />
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
