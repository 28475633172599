import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import Feedback from '@capp.micro/shared/components';
import PropTypes from 'prop-types';
//This component accepts three quiz types, passed down as a string in the "type" prop: scenario, test&learn, pop-quiz
// It also accepts the "questions" prop, which is an array of objects containing 4 different keys

export const Quiz = ({
  questions,  addObjectToLocalStorage
}) => {

  Quiz.propTypes = {
    questions: PropTypes.array,
    addObjectToLocalStorage: PropTypes.func
  }

  const [responses, setResponses] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [round, setRound] = useState(0);
  const [feedbackToShow, setFeedbackToShow] = useState(
    questions[round].feedback ? questions[round].feedback.defaultFeedback : ''
  );
  const currentQuestion = round + 1;

  const [selectedAnswer, setSelectedAnswer] = useState(null);

  const handleClick = (e) => {
    const newResponse = {
      question: questions[round].question,
      answer: e.target.innerHTML,
    };

    const updatedResponses = [...responses];
    if (updatedResponses.length > round) {
      updatedResponses[round] = newResponse;
    } else {
      updatedResponses.push(newResponse);
    }
  
    setSelectedAnswer(newResponse.answer);
    setResponses(updatedResponses);
  };
  const [finished, setFinished] = useState(false);

  const checkAnswers = (array) => {
    if (questions[0].feedback) {
      return array.map((individualResponse, index) => {
        const feedback = individualResponse.answer === questions[index].correctAnswer
          ? questions[index].feedback.correctFeedback
          : questions[index].feedback.incorrectFeedback;
  
        return { ...individualResponse, feedback };
      });
    } else {
      return array;
    }
  };

  const handleSubmit = () => {
    if(questions[0].showFeedback)
  {  const checkedAnswers = checkAnswers(responses);
    checkedAnswers[round].answer === questions[round].correctAnswer
      ? setFeedbackToShow(questions[round].feedback.correctFeedback)
      : setFeedbackToShow(questions[round].feedback.incorrectFeedback);
    setFinished(true);
    setAnswers(checkedAnswers);}
    else {
      setAnswers(responses)
    }
  };

  const handleNext = () => {
    setRound((prevRound) => prevRound + 1);
  };

  const component = (
    <div className="quiz-session">
      <div className="quiz-header">
        <div className="quiz-questions">
          Question {currentQuestion.toString()} of{' '}
          {Object.keys(questions).length}{' '}
        </div>
      </div>
      <div className="quiz-question">{questions[round].question}</div>

      <div className="quiz-answers">
        <div className="quiz-answers-sub-one">
          {questions[round].answers.map((answer) => {
            return (
              <div
                className={`quiz-answers-individual ${
                  answer === selectedAnswer ? 'selected' : ''
                }`}
                onClick={handleClick}
                key={questions[round].answers.indexOf(answer)}
              >
                {answer}
              </div>
            );
          })}
        </div>
        <div className="quiz-submit">
          {round + 1 === questions.length ? (
            <Button onClick={handleSubmit} disabled={responses.length === 0}>
              Submit
            </Button>
          ) : (
            <Button
              onClick={handleNext}
              type="button"
              className="btn btn-primary"
              disabled={responses.length === 0}
            >
              Next
            </Button>
          )}
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    if (answers.length > 0) {
      addObjectToLocalStorage("answers", answers
      );
    } // This will always use latest value of answers etc
  }, [answers, addObjectToLocalStorage]);
  return (
    <div>
      {finished ? <Feedback feedbackText={feedbackToShow} /> : component}
    </div>
  );
};

export default Quiz;
