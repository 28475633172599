import React from "react"
import Navigation from "./navigation";
import logo from "../images/brand-logo.svg";
import PropTypes from 'prop-types';

const Header = ({ Brand }) => {

  Header.propTypes = {
    Brand: PropTypes.object,
  }

  return (
    <header
      style={{
        marginBottom: `1.45rem`,
        display: `flex`,
        displayDirection: `column`
      }}
    >
   <div
        style={{
          margin: `0 auto`,
          width: `100%`,
          maxWidth: `100%`,
          padding: `1.45rem 0`
        }}
      >
        <Navigation
          logo={logo}
          alt={Brand.LOGO_ALT}
        />
        
      </div>
    </header>
  )
}

// Header.propTypes = {
//   siteTitle: PropTypes.string,
// }

// Header.defaultProps = {
//   siteTitle: ``,
// }

export default Header
