import React, {useEffect, useState} from "react"
import Brand from "../Brand";
import { Button, Col, Container, Row } from "react-bootstrap"
import { Helmet } from "react-helmet"
import DashboardLayout from "../components/dashboard-layout";
import { Link } from 'gatsby';
import videoUrl from "../images/Roundel-matching-tool-Deloitte.mp4"

const Index = () => {

  const [resultsPresent, setResultsPresent] = useState(null)

  useEffect(() => {
    // Check if localStorage is available before using it
    if (typeof window !== 'undefined') {
      const resultsFound = localStorage.getItem("answers");
      if (resultsFound) {
       setResultsPresent(resultsFound)
      }
    }
  }, []);

  const resetResults = () => {
    // Check if localStorage is available before using it
    if (typeof window !== 'undefined') {
      localStorage.removeItem("answers");
      window.location = '/modules/1';
    }
  }

  return (
    <DashboardLayout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{Brand.PAGE1_TITLE}</title>
      </Helmet>
      <div className='main-window' style={{
        margin: `0 auto`,
        height: '90vh',
        width: '100%',
        padding: '0',
      }}>
          <Container>
            <Row>
              <Col xs={12} lg={6} className="text-container">
            <div className="text">
            <h1 className="sign-up-title" style={{backgroundColor: "transparent", paddingLeft: "0px"}}>
            Discover your path
            </h1>
            <p style={{marginTop: "20px"}} className="impact-title">Choose your impact</p>
            <p style={{marginTop: "0px"}}>In a sea of opportunities, deciding what's most important to you can feel really daunting. But you don’t need to have it all figured out just yet. We’re here to help you make the right choices, for you.</p> 
            <p style={{marginTop: "0px"}}>Our quiz explores your strengths and preferences, giving you personalised recommendations for the career paths we think you’d most enjoy.</p> 
            <p style={{marginTop: "0px"}} className="impact-title">Get started now and Discover Your Path!</p> 

            <div className="button-container">
                {resultsPresent ? (<div style={{ display: 'flex', gap: '20px'}}><Link className="btn btn-primary start-now" to={`/results`}>See your results</Link><Button className="btn-secondary" onClick={() => resetResults()}>Retake</Button></div>) : (<Link className="btn btn-primary start-now" to={`/modules/1`}>Start now</Link>)} 
            </div>  
            <p style={{marginTop: "20px"}}>Please turn your sound on for the best experience.</p> 
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <video width="320" height="240" loop muted autoPlay>
              <source src={videoUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </Col>
            </Row>
        </Container>
      </div>
 
    </DashboardLayout>
    
  )
};

export default Index
