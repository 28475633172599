const page3Data = {
  "id": "80ca962f-24ca-477f-928f-171bd3a2571e",
  "number": "3",
  "title": "",
  "image": "https://apifiles.cappassessments.com/Deloitte2023/findyourpath/question3.png",
  "roundel": "https://apifiles.cappassessments.com/Deloitte/collaborate.png",
  "content": [
     
  ],
  "afterResponse": [
      ""
  ],

  "resourceType":[
    {
      id: 'a2fbb7ab-054a-48ca-b8fd-134ea06f5f72',
      name: 'video',
      videoUrl:
        'https://apifiles.cappassessments.com/Deloitte2023/Videos/Video5_Tom_Collaborate_v2.mp4',
    },
  ], 

  "responseType": [
  ]
};

module.exports = page3Data;
